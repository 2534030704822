// Generated by Framer (86ad780)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Caret from "https://framerusercontent.com/modules/0scrOn7O2aLVlhVMWhXF/iFNoOwTuNwQRqVlVVYed/Vv7lXSsrv.js";
const CaretFonts = getFonts(Caret);

const cycleOrder = ["MJuQXtRCv", "tG9G3K61W"];

const serializationHash = "framer-b8q4I"

const variantClassNames = {MJuQXtRCv: "framer-v-1eaqy1k", tG9G3K61W: "framer-v-1hdiwm1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Closed: "MJuQXtRCv", Open: "tG9G3K61W"}

const getProps = ({height, hover, id, width, ...props}) => { return {...props, PWSlWeqS6: hover ?? props.PWSlWeqS6, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "MJuQXtRCv"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;hover?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, PWSlWeqS6, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "MJuQXtRCv", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter1nzhzpa = activeVariantCallback(async (...args) => {
if (PWSlWeqS6) {
const res = await PWSlWeqS6(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1eaqy1k", className, classNames)} data-framer-name={"Closed"} data-highlight layoutDependency={layoutDependency} layoutId={"MJuQXtRCv"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnter1nzhzpa} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} {...addPropertyOverrides({tG9G3K61W: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-1dp01ru-container"} layoutDependency={layoutDependency} layoutId={"y1GNKPFb4-container"}><Caret height={"100%"} id={"y1GNKPFb4"} layoutId={"y1GNKPFb4"} variant={"wnipua7N_"} width={"100%"} {...addPropertyOverrides({tG9G3K61W: {variant: "BJYJhvClj"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-b8q4I.framer-1v1tp5w, .framer-b8q4I .framer-1v1tp5w { display: block; }", ".framer-b8q4I.framer-1eaqy1k { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: center; overflow: hidden; padding: 5px 0px 5px 0px; position: relative; width: min-content; }", ".framer-b8q4I .framer-1dp01ru-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-b8q4I.framer-1eaqy1k { gap: 0px; } .framer-b8q4I.framer-1eaqy1k > * { margin: 0px; margin-left: calc(5px / 2); margin-right: calc(5px / 2); } .framer-b8q4I.framer-1eaqy1k > :first-child { margin-left: 0px; } .framer-b8q4I.framer-1eaqy1k > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 14
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"tG9G3K61W":{"layout":["auto","auto"]}}}
 * @framerVariables {"PWSlWeqS6":"hover"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerRDp3Vx1sc: React.ComponentType<Props> = withCSS(Component, css, "framer-b8q4I") as typeof Component;
export default FramerRDp3Vx1sc;

FramerRDp3Vx1sc.displayName = "Trigger Copy 3";

FramerRDp3Vx1sc.defaultProps = {height: 24, width: 14};

addPropertyControls(FramerRDp3Vx1sc, {variant: {options: ["MJuQXtRCv", "tG9G3K61W"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}, PWSlWeqS6: {title: "Hover", type: ControlType.EventHandler}} as any)

addFonts(FramerRDp3Vx1sc, [{explicitInter: true, fonts: []}, ...CaretFonts], {supportsExplicitInterCodegen: true})